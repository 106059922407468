import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";
import Img from "gatsby-image";

import { theme } from "../../../utils/global-styles/theme";

import Link4Arrow from "../../shared/Link4Arrow";

const CourseBookPromo = ({ promoDetails }) => {
  const BookPromoGrid = styled.div`
      height: max-content;
      background-color: ${theme.colors.light};
      display: grid;
      grid-template-columns: 224px 224px auto 224px;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template-columns: 14px 100px auto 14px;
      }
    `,
    TextContentContainer = styled.div`
      padding: 50px 0;
      grid-area: 1 / 3 / 1 / 3;
      a {
        display: block;
        margin-bottom: 5px;
      }
    `,
    ImageContainer = styled.div`
      grid-area: 1 / 2 / 1 / 2;
      padding: 50px 120px 50px 0;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 50px 10px 0 0;
      }
    `,
    StyledMarkdown = styled(Markdown)`
      h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
      }
      em {
        font-style: italic;
      }
      p {
        margin: 1em 0;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 23px;
      }
    `,
    Text = styled.p`
      font-family: ${theme.fontFaces.headers};
      margin-top: 7px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.34px;
      line-height: 14px;
    `;
  let links = [
    promoDetails.link,
    promoDetails.secondLink,
    promoDetails.thirdLink,
  ];
  links = links.filter(link => link);
  const renderLinks = () => {
    return links.map(link => {
      return (
        <Link4Arrow to={`${link.url}/`} isExternalLink={true}>
          {link.linkText}
        </Link4Arrow>
      );
    });
  };
  return (
    <BookPromoGrid>
      <TextContentContainer>
        <StyledMarkdown
          options={{
            overrides: {
              p: {
                props: {
                  className: "p5",
                },
              },
              a: {
                props: {
                  className: "primary",
                },
              },
            },
          }}
        >
          {promoDetails.description.description}
        </StyledMarkdown>
        {links.length > 0 && renderLinks()}
        <Text>{promoDetails.factsHighlight}</Text>
      </TextContentContainer>
      <ImageContainer>
        <Img fluid={promoDetails.image.fluid} />
      </ImageContainer>
    </BookPromoGrid>
  );
};

CourseBookPromo.propTypes = {
  promoDetails: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      link: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      secondLink: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      thirdLink: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      image: PropTypes.shape({
        fluid: PropTypes.shape({
          srcSet: PropTypes.string,
        }),
      }),
    })
  ),
};

export default CourseBookPromo;
