import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";

import { theme } from "../../../utils/global-styles/theme";

import BlurredContentContainer from "../../shared/BlurredContentContainer/BlurredContentContainer";

const AboutTheInstructors = ({
  instructorsInfoArray,
  aboutTheInstructorsTitle,
  readMoreLessTitle,
}) => {
  const AboutInstructorsGrid = styled.div`
      display: grid;
      grid-template-rows: 1fr 88px;
      grid-template-columns: 324px 1fr 324px;
      font-family: ${theme.fontFaces.headers};
      padding-top: 66px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 168px 1fr 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: 1fr 18px;
        grid-template-columns: 14px 1fr 14px;
      }
    `,
    AboutInstructorsContentContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      height: max-content;
    `,
    Title = styled.h2`
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 42px;
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `,
    InstructorName = styled.h3`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        line-height: 24px;
      }
    `,
    AboutInstructorsText = styled.p`
      font-family: ${theme.fontFaces.paragraphs};
      font-size: 20px;
      letter-spacing: -0.57px;
      line-height: 31px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 15px;
        letter-spacing: -0.43px;
        line-height: 20px;
      }
    `;
  return (
    <AboutInstructorsGrid>
      <AboutInstructorsContentContainer>
        <Title className="primary">
          {aboutTheInstructorsTitle || "About the Instructor"}
          {instructorsInfoArray && instructorsInfoArray.length > 1 ? "s" : ""}
        </Title>
        {instructorsInfoArray &&
          instructorsInfoArray.map((instructor, index) => {
            return (
              <BlurredContentContainer
                key={index}
                readMoreLessTitle={readMoreLessTitle}
                blurHeight="120px"
                content={
                  <>
                    <InstructorName>{instructor.name}</InstructorName>
                    <Markdown
                      options={{
                        overrides: {
                          p: {
                            props: {
                              className: "markdown-paragraph",
                            },
                          },
                        },
                      }}
                      className="markdown-paragraph"
                    >
                      {instructor.bioAbout.bioAbout}
                    </Markdown>
                  </>
                }
              />
            );
          })}
      </AboutInstructorsContentContainer>
    </AboutInstructorsGrid>
  );
};

AboutTheInstructors.propTypes = {
  instructorsInfoArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      bioAbout: PropTypes.shape({
        bioAbout: PropTypes.string,
      }),
    })
  ),
};

export default AboutTheInstructors;
