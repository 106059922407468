import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { theme } from "../../../utils/global-styles/theme";

import CourseGrid from "../../CatalogCard/CatalogGrid";

const CourseThemeCatalog = ({ heading, moreCourses }) => {
  const CourseThemeGrid = styled.div`
      display: grid;
      grid-template-rows: auto 42px auto;
      grid-template-columns: 1fr;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: auto 12px auto;
      }
    `,
    HeadingContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      z-index: 2;
      padding-left: 80px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 0 40px;
      }
    `,
    CourseContainer = styled.div`
      grid-area: 2 / 1 / span 2 / span 1;
      z-index: 1;
    `,
    CourseThemeCatalogHeading = styled.h1`
      line-height: 50px;
      margin-bottom: 40px;
      font-weight: bold;
      font-family: ${theme.fontFaces.headers};
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      }
    `;
  return (
    <CourseThemeGrid>
      <HeadingContainer>
        <CourseThemeCatalogHeading>{heading}</CourseThemeCatalogHeading>
      </HeadingContainer>
      <CourseContainer>
        <CourseGrid
          courses={moreCourses}
          isNotCourseCatalogPage={true}
          isCourseDetailPage={true}
        />
      </CourseContainer>
    </CourseThemeGrid>
  );
};

CourseThemeCatalog.propTypes = {
  heading: PropTypes.string,
  courseThemeMatchArray: PropTypes.array,
};

export default CourseThemeCatalog;
