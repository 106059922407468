import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";

import { theme } from "../../../utils/global-styles/theme";

const Supporters = ({ supportersArray, supportersDescriptionHeading }) => {
  const SupportersGrid = styled.div`
      display: grid;
      grid-template-rows: 1fr 120px;
      grid-template-columns: 324px 1fr 324px;
      font-family: ${theme.fontFaces.headers};
      padding-top: 66px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 168px 1fr 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: 1fr 50px;
        grid-template-columns: 14px 1fr 14px;
      }
    `,
    SupportContentContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      height: max-content;
    `,
    Title = styled.h2`
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 42px;
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `,
    SupporterImg = styled(Img)`
      margin-bottom: 20px;
    `,
    SupporterDescriptionText = styled.p`
      font-family: ${theme.fontFaces.paragraphs};
      font-size: 20px;
      letter-spacing: -0.57px;
      line-height: 31px;
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 15px;
        letter-spacing: -0.43px;
        line-height: 20px;
      }
    `,
    SupportWebsiteLink = styled.a`
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.34px;
      line-height: 14px;
      text-align: right;
      border-bottom: 3px solid;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.28px;
        line-height: 12px;
      }
    `,
    SupportersContainer = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (min-width: ${theme.breakpoints.mobile}) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    `,
    SingleSupporter = styled.div`
      display: block;
      width: 90%;
      margin-bottom: 50px;

      @media only screen and (min-width: ${theme.breakpoints.mobile}) {
        width: ${props => props.supportersCount ? '90%' : '45%'};
      }
    `;

  return (
    <SupportersGrid>
      <SupportContentContainer>
        <Title className="primary">
          {supportersDescriptionHeading || "Supporters"}
        </Title>
        <SupportersContainer>
          {supportersArray?.map((supporter, index) => {
              return (
                <SingleSupporter supportersCount={supportersArray?.length === 1} key={index}>
                  {supporter.photoLogo && (
                    <SupporterImg
                      fluid={supporter.photoLogo.fluid}
                      alt={supporter.photoLogo.description}
                      css={css`
                        height: 360px / ${supporter.photoLogo.fluid.aspectRatio};
                        width: 100%;

                        @media only screen and (max-width: ${theme.breakpoints
                            .mobile}) {
                          height: 220px /
                            ${supporter.photoLogo.fluid.aspectRatio};
                          width: 220px;
                        }
                      `}
                    />
                  )}
                  {supporter.bioAbout && (
                    <SupporterDescriptionText>
                      {supporter.bioAbout.bioAbout}
                    </SupporterDescriptionText>
                  )}
                  {supporter.cta && (
                    <SupportWebsiteLink
                      href={supporter.cta.url}
                      target="_blank"
                      className="primary primary-border"
                    >
                      {supporter.cta.linkText.toUpperCase()}
                    </SupportWebsiteLink>
                  )}
                </SingleSupporter>
              );
            })}
        </SupportersContainer>
      </SupportContentContainer>
    </SupportersGrid>
  );
};

Supporters.propTypes = {
  supportersArray: PropTypes.arrayOf(
    PropTypes.shape({
      photoLogo: PropTypes.shape({
        fluid: PropTypes.shape({
          srcSet: PropTypes.string,
          description: PropTypes.string,
        }),
      }),
      bioAbout: PropTypes.shape({
        bioAbout: PropTypes.string,
      }),
      cta: PropTypes.shape({
        url: PropTypes.string,
        linkText: PropTypes.string,
      }),
    })
  ),
};

export default Supporters;
