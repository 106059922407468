import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import CourseDetail from "../components/CourseDetail/CourseDetail";
import useEnrollmentNotificationData from "../hooks/enrollment-notification-data";
import { _get } from "../utils/services/api.service";
import moment from "moment";
import "moment/min/locales";
import { getUserDetails, isLoggedIn } from "../utils/services/methods";
import { _post } from "../utils/services/api.service";

const CourseDetailPage = ({
  data, 
  location: { pathname },
  pageContext: { marketingModal },
}) => {
  const enrollmentNotificationData = useEnrollmentNotificationData(),
    [enrollmentNotification, setEnrollmentNotification] = useState(null),
    [enrollmentStatus, setEnrollmentStatus] = useState("not enrolled"),
    [courseStart, setCourseStart] = useState(null),
    [loading, setLoading] = useState(false),
    [sso, setSso] = useState(false),
    [redirectUrl, setRedirectUrl] = useState(""),
    [validatingToken, setValidatingToken] = useState(false),
    [showFlexiblePaymentModal, setShowFlexiblePaymentModal] = useState(false),
    {
      courseImage,
      languageCode,
      seoImage,
      courseType,
      courseId,
      viewCourseCatalog,
      title,
      provider,
      providerUrl,
      shortDescription,
      theme,
      courseCost,
      courseAvailability,
      courseDuration,
      cta,
      courseBookPromo,
      financialAid,
      metaDescription,
      videoPreviewUrl,
      videoThumbnail,
      watchVideoCaption,
      whatYoullLearn,
      whatYoullLearnHeading,
      courseBenefits,
      testimonials,
      courseSyllabusHeader,
      courseSyllabus,
      courseDescriptionHeading,
      readMore: readMoreLessTitle,
      courseDescription,
      heading1: aboutTheInstructorsTitle,
      aboutTheInstructors,
      heading2: supportersDescriptionHeading,
      supporters,
      aboutThisCourseType,
      courseStartDate,
      courseReleaseDate,
      slug,
      flexiblePayment,
      suggestedPrice,
      moreCourses,
      moreCoursesHeading,
      newTheme,
      courseCostInOtherCurrencies,
    } = data.courseDetail,
    setEnrollmentStatusAndCourseStartDate = (enrollment, startDate) => {
      setEnrollmentStatus(enrollment);
      setCourseStart(startDate ? startDate : null);
    };
  moment.locale(languageCode);
  const formattedDate = moment(courseStartDate)
      .utcOffset("-0700")
      .format("MMMM D, YYYY"),
    formattedCourseStartDate =
      formattedDate === "Invalid date" ? "On-Demand" : formattedDate,
    token = () => {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let token = url.searchParams.get("token");
      return token;
    };

  useEffect(() => {
    validateToken();

    if (isLoggedIn()) {
      if (!token()) {
        setLoading(true);
        const getEnrollmentStatusAndCourseDetails = async () => {
          const userDetails = await getUserDetails(),
            enrollment = await _get({
              endpoint: `enrollments/status?external_id=${userDetails.external_id}&provider_url=${providerUrl}`,
            });
          if (enrollment.success) {
            setLoading(false);
            return enrollment.data.message === "User has enrolled"
              ? setEnrollmentStatusAndCourseStartDate(
                  "enrolled",
                  enrollment.data.course.startDate
                )
              : setEnrollmentStatusAndCourseStartDate("not enrolled");
          }
          setLoading(false);
        };
        getEnrollmentStatusAndCourseDetails();
      }
    }
  }, [title, enrollmentStatus]);

  const validateToken = async () => {
    if (token()) {
      setSso(true);
      const requestData = { course_id: courseId, token: token() };
      setValidatingToken(true);
      const validateTokenResponse = await _post({
        endpoint: `partners/validate-token`,
        args: requestData,
        isPublic: true,
      });
      setValidatingToken(false);
      if (validateTokenResponse.success) {
        if (validateTokenResponse.data.enrolled) {
          setEnrollmentStatus("enrolled");
          setRedirectUrl(
            `${process.env.GATSBY_login_url}sso?course_id=${courseId}&resume=true`
          );
        } else {
          localStorage.setItem(
            "pendingCourseEnrollmentDetails",
            JSON.stringify({
              courseType,
              courseId,
              provider,
              providerUrl,
              courseTheme: theme.themeName,
              title: title,
              courseCost,
              courseAvailability,
              courseDuration,
              slug,
            })
          );
          setRedirectUrl(
            `${process.env.GATSBY_login_url}sso?course_id=${courseId}`
          );
        }
      } else {
        if (validateTokenResponse?.errors[0]?.message?.error_url) {
          if (typeof window !== "undefined") {
            window.location.replace(
              validateTokenResponse.errors[0].message.error_url
            );
          }
        } else {
          setEnrollmentNotification({
            status: "fail",
            type: "course",
            message:
              "We are unable to verify your details right now. Kindly contact your company or SSO provider for assistance",
          });
        }
      }
    } else {
      setSso(false);
    }
  };
  const seoImageUrl = seoImage ? seoImage.file.url : courseImage.file.url,
    catalogLinkText =
      viewCourseCatalog !== null
        ? viewCourseCatalog.linkText
        : "VIEW COURSE CATALOG",
    catalogUrl =
      viewCourseCatalog !== null ? viewCourseCatalog.url : "/courseCatalog";

  return data.courseDetail.courseImage.fluid ? (
    <Layout
      loading={loading}
      pathname={pathname}
      title={title}
      description={metaDescription}
      image={seoImageUrl}
      locale={languageCode}
      marketingModal={marketingModal}
      fullDeviceWidth
    >
      <CourseDetail
        hubspotClassName={"mrkt-enrl-course"}
        courseImage={courseImage}
        courseType={courseType}
        courseId={courseId}
        title={title}
        provider={provider}
        providerUrl={providerUrl}
        shortDesc={shortDescription.shortDescription}
        themeNumber={theme.themeNumber}
        theme={theme.theme}
        viewCourseCatalogText={catalogLinkText}
        viewCourseCatalogLink={catalogUrl}
        courseCost={courseCost}
        courseAvailability={courseAvailability}
        courseDuration={courseDuration}
        ctaText={cta.linkText}
        courseBookPromo={courseBookPromo}
        languageCode={languageCode}
        financialAid={financialAid}
        ctaUrl={cta.url}
        videoPreviewUrl={videoPreviewUrl}
        videoThumbnail={videoThumbnail}
        watchVideoCaption={watchVideoCaption}
        whatYoullLearn={whatYoullLearn}
        whatYoullLearnHeading={whatYoullLearnHeading}
        courseBenefits={courseBenefits}
        testimonials={testimonials}
        courseSyllabusHeader={courseSyllabusHeader}
        courseSyllabus={courseSyllabus}
        courseDescriptionHeading={courseDescriptionHeading}
        courseDescription={
          courseDescription && courseDescription.courseDescription
        }
        readMoreLessTitle={readMoreLessTitle}
        aboutTheInstructorsTitle={aboutTheInstructorsTitle}
        aboutTheInstructors={aboutTheInstructors}
        supportersDescriptionHeading={supportersDescriptionHeading}
        supporters={supporters}
        aboutThisCourseType={aboutThisCourseType}
        moreCourses={moreCourses}
        moreCoursesHeading={moreCoursesHeading}
        enrollmentStatus={enrollmentStatus}
        courseStartDate={formattedCourseStartDate}
        headerCourseStartDate={courseStartDate}
        courseReleaseDate={courseReleaseDate}
        enrollmentNotificationData={enrollmentNotificationData}
        enrollmentNotification={enrollmentNotification}
        setEnrollmentNotification={setEnrollmentNotification}
        isLoggedIn={isLoggedIn}
        setLoading={setLoading}
        slug={slug}
        sso={sso}
        redirectUrl={redirectUrl}
        validatingToken={validatingToken}
        flexiblePayment={flexiblePayment}
        showFlexiblePaymentModal={showFlexiblePaymentModal}
        setShowFlexiblePaymentModal={setShowFlexiblePaymentModal}
        suggestedPrice={suggestedPrice}
        newTheme={newTheme}
        courseCostInOtherCurrencies={courseCostInOtherCurrencies}
      />
    </Layout>
  ) : null;
};

export const query = graphql`
  query($courseId: String!, $theme: String, $languageCode: String!) {
    courseDetail: contentfulCourseDetailPage(
      courseId: { eq: $courseId }
      node_locale: { eq: $languageCode }
    ) {
      ...CourseDetail
    }
    courseThemeMatch: allContentfulCourseDetailPage(
      filter: {
        theme20: { theme: { eq: $theme } }
        courseId: { ne: $courseId }
        showOnCourseCataloguePage: { eq: true }
      }
      limit: 5
    ) {
      edges {
        node {
          ...CourseDetail
        }
      }
    }
  }
`;

export default CourseDetailPage;
