import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import Img from "gatsby-image";

const BioCard = ({ image, name, title, backgroundColor }) => {
  const Card = styled.div`
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 534px;
      }
    `,
    DetailsContainer = styled.div`
      padding: 2em;
    `,
    Name = styled.h4`
      margin-bottom: 0.5em;
    `,
    Title = styled.p`
  
    `,
    splitTitleByComma = title.split(","),
    titleDefinition = splitTitleByComma[1] ? `${splitTitleByComma[0]},` : title,
    organization = splitTitleByComma[1] || "";
  return (
    <Card>
      {image?.fluid ? <Img fluid={image.fluid} /> : <img />}
      <DetailsContainer className={backgroundColor}>
        <Name>{name}</Name>
        <Title>
          {titleDefinition} <br />
          {organization}
        </Title>
      </DetailsContainer>
    </Card>
  );
};

BioCard.propTypes = {};

export default BioCard;
