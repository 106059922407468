import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { theme } from "../../utils/global-styles/theme";

import BulletIcon from "../shared/BlurredContentContainer/Assets/BulletIcon";
import VideoPreview from "./VideoPreview";

const LearnSection = ({
  learningBullets,
  hasGrayBackground,
  isSidebar,
  sidebarStyle,
  heading,
  subheading,
  bigTitle,
  videoPreviewUrl,
  videoThumbnail,
  caption,
  title,
}) => {
  const LearningListGrid = styled.div`
      height: max-content;
      display: grid;
      font-family: ${theme.fontFaces.headers};
    `,
    LearningListContent = styled.div`
      grid-area: ${videoThumbnail && videoPreviewUrl
        ? `2 / 2 / span 1 / span 1`
        : `2 / 2 / 2 / 2`};
      height: max-content;
      margin-left: ${videoThumbnail && videoPreviewUrl ? `` : `48px`};
      margin-right: 0.8em;

      @media only screen and (max-width: 1180px) {
        padding-top: ${videoThumbnail && videoPreviewUrl ? `5em` : ``};
        margin-left: 0;
        grid-area: 3 / 2 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding-top: ${videoThumbnail && videoPreviewUrl ? `3em` : ``};
        grid-area: 3 / 2 / span 1 / span 1;
      }
    `,
    VideoPreviewSection = styled.div`
      grid-area: 2 / 3 / span 1 / span 1;
      height: max-content;
      align-self: center;

      @media only screen and (max-width: 1180px) {
        grid-area: 2 / 2 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 2 / 2 / span 1 / span 1;
      }
    `,
    LearningListTitle = styled.h2`
      font-weight: bold;
      letter-spacing: -0.91px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-weight: bold;
        letter-spacing: -0.52px;
      }
    `,
    LearningListTitleBig = styled.h1`
      font-weight: bold;
      letter-spacing: -0.91px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-weight: bold;
        letter-spacing: -0.52px;
      }
    `,
    LearnListItemSubGrid = styled.div`
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 20px 1fr;
      margin-top: 30px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-top: 20px;
      }
    `,
    BulletIconContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding-top: 6px;
    `,
    LearnListItemText = styled.p`
      grid-area: 1 / 2 / span 1 / span 1;
      font-weight: 500;
      letter-spacing: -0.45px;
      margin-left: 21px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-weight: 500;
        letter-spacing: -0.39px;
        margin-left: 17px;
      }
    `;

  return (
    <LearningListGrid
      className={hasGrayBackground ? "light-bg" : ""}
      css={
        isSidebar
          ? sidebarStyle
          : css`
              grid-template-rows: 70px 1fr 80px;
              grid-template-columns: 1fr 328px 784px 328px 1fr;

              @media only screen and (max-width: 5600px) {
                grid-template-rows: 70px 1fr 80px;
                grid-template-columns: ${videoThumbnail && videoPreviewUrl
                  ? `80px 1fr 1fr 80px`
                  : `284px 1fr 284px`};
              }

              @media only screen and (max-width: 1180px) {
                grid-template-rows: 80px auto 1fr 80px;
                grid-template-columns: 80px 1fr 80px;
              }

              @media only screen and (max-width: ${theme.breakpoints.mobile}) {
                grid-template-rows: 50px auto 1fr 50px;
                grid-template-columns: 1em 1fr 1em;
              }
            `
      }
    >
      <LearningListContent>
        {bigTitle ? (
          <LearningListTitleBig>
            {heading ? heading : "What You'll Learn"}
          </LearningListTitleBig>
        ) : (
          <LearningListTitle>
            {heading ? heading : "What You'll Learn"}
          </LearningListTitle>
        )}
        {subheading && <p>{subheading}</p>}
        {learningBullets.map((listItem, index) => {
          return (
            <LearnListItemSubGrid key={index}>
              <BulletIconContainer>
                <BulletIcon height="19px" width="19px" />
              </BulletIconContainer>
              <LearnListItemText>{listItem}</LearnListItemText>
            </LearnListItemSubGrid>
          );
        })}
      </LearningListContent>
      {videoPreviewUrl && videoThumbnail && (
        <VideoPreviewSection>
          <VideoPreview
            videoPreviewUrl={videoPreviewUrl}
            title={title}
            videoThumbnail={videoThumbnail}
            caption={caption}
          />
        </VideoPreviewSection>
      )}
    </LearningListGrid>
  );
};

LearnSection.propTypes = {
  isSidebar: PropTypes.bool,
  learningBullets: PropTypes.arrayOf(PropTypes.string),
  sidebarStyle: PropTypes.object,
  hasGrayBackground: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  bigTitle: PropTypes.bool,
};

export default LearnSection;
