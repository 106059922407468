import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";

import { theme } from "../../../utils/global-styles/theme";

const FinancialAid = ({ description, link }) => {
  const FinancialAidGrid = styled.div`
      height: max-content;
      display: grid;
      grid-template-columns: 224px auto 50px 224px;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        text-align: center;
        grid-template-rows: 30px auto 30px 30px;
        grid-template-columns: 30px auto 30px;
      }
    `,
    TextContainer = styled.div`
      grid-area: 1 / 2 / 1/ 2;
      padding: 50px 0;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 0 20px 0;
        grid-area: 2 / 2 / 2/ 2;
      }
    `,
    LinkContainer = styled.div`
      grid-area: 1 / 3 / 1/ 3;
      padding: 50px 0;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 3 / 2 / 3/ 2;
        padding-top: 0;
      }
    `,
    CtaLink = styled.a`
      font-weight: bold;
      border-bottom: 4px solid;
      text-transform: uppercase;
      cursor: pointer;
    `,
    StyledMarkdown = styled(Markdown)`
      color: white;
      p {
        font-family: ${theme.fontFaces.agDemiMd};
      }
    `;
  return (
    <FinancialAidGrid className="primary-bg">
      <TextContainer>
        <StyledMarkdown
          options={{
            overrides: {
              p: {
                props: {
                  className: "white",
                },
              },
            },
          }}
        >
          {description}
        </StyledMarkdown>
      </TextContainer>
      <LinkContainer>
        <CtaLink
          href={link.url}
          className="white secondary-border-hover"
          target="_blank"
        >
          {link.linkText}
        </CtaLink>
      </LinkContainer>
    </FinancialAidGrid>
  );
};

FinancialAid.propTypes = {
  description: PropTypes.string,
  link: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default FinancialAid;
