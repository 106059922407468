import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import axios from "axios";
import { _post } from "../../utils/services/api.service";

import EnrollmentNotification from "./child-components/EnrollmentNotification";
import CourseDetailHero from "../CourseDetailV2/CourseDetailHero";
import StickyHeader from "./child-components/StickyHeader";
import VideoPreview from "./child-components/VideoPreview";
import LearnSection from "../CourseDetailV2/LearnSection";
import CourseBenefits from "./child-components/CourseBenefits";
import TestimonialSlider from "./child-components/TestimonialSlider";
import CourseSyllabus from "./child-components/CourseSyllabus/CourseSyllabus";
import CourseDescription from "./child-components/CourseDescription";
import AboutTheInstructors from "./child-components/AboutTheInstructors";
import Supporters from "./child-components/Supporters";
import CourseTypeBenefits from "../CourseTypeBenefits/CourseTypeBenefits";
import CourseThemeCatalog from "./child-components/CourseThemeCatalog";
import CourseBookPromo from "./child-components/CourseBookPromo";
import FinancialAid from "./child-components/FinancialAid";
import FlexiblePaymentModal from "../../components/shared/FlexiblePaymentModal/FlexiblePaymentModal";
import FeaturedInThisCourse from "../FeaturedInThisCourse/FeaturedInThisCourse";
import {
  handleGoToCourseClick,
  getUserDetails,
  saveUserDetails,
} from "../../utils/services/methods";
import styled from "@emotion/styled";

const CourseDetail = ({
  hubspotClassName,
  courseImage,
  languageCode,
  courseType,
  courseId,
  title,
  provider,
  providerUrl,
  shortDesc,
  themeNumber,
  theme,
  viewCourseCatalogText,
  viewCourseCatalogLink,
  courseCost,
  courseAvailability,
  courseDuration,
  ctaText,
  ctaUrl,
  courseBookPromo,
  financialAid,
  videoPreviewUrl,
  videoThumbnail,
  watchVideoCaption,
  whatYoullLearn,
  whatYoullLearnHeading,
  courseBenefits,
  testimonials,
  courseSyllabusHeader,
  courseSyllabus,
  courseDescriptionHeading,
  courseDescription,
  readMoreLessTitle,
  aboutTheInstructorsTitle,
  aboutTheInstructors,
  supportersDescriptionHeading,
  supporters,
  aboutThisCourseType,
  moreCourses,
  moreCoursesHeading,
  enrollmentStatus,
  courseStartDate,
  headerCourseStartDate,
  enrollmentNotificationData,
  enrollmentNotification,
  setEnrollmentNotification,
  isLoggedIn,
  setLoading,
  slug,
  sso,
  redirectUrl,
  validatingToken,
  flexiblePayment,
  showFlexiblePaymentModal,
  setShowFlexiblePaymentModal,
  newTheme,
  courseCostInOtherCurrencies,
}) => {
  const ContentWrapper = styled.div`
    margin: 0 auto;
  `;
  const [currency, setCurrency] = useState("USD");
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => response.data)
      .then(location => {
        if (location.currency === "GBP" || location.currency === "EUR") {
          setCurrency(location.currency);
        }
      });
  }, []);

  let currencySymbol = "$";
  if (currency === "GBP") {
    currencySymbol = "£";
  } else if (currency === "EUR") {
    currencySymbol = "€";
  }
  let courseCostBasedOnLocation = "";
  let suggestedPrice;
  if (courseCostInOtherCurrencies && currency === "GBP" && courseCost) {
    if (courseCost === "Free") {
      courseCostBasedOnLocation = courseCost;
    } else {
      courseCostBasedOnLocation = courseCostInOtherCurrencies?.pound;
    }
    suggestedPrice = courseCostInOtherCurrencies?.pound;
  } else if (courseCostInOtherCurrencies && currency === "EUR") {
    if (courseCost === "Free") {
      courseCostBasedOnLocation = courseCost;
    } else {
      courseCostBasedOnLocation = courseCostInOtherCurrencies?.euro;
    }
    suggestedPrice = courseCostInOtherCurrencies?.euro;
  } else {
    courseCostBasedOnLocation = courseCost;
    suggestedPrice = courseCostInOtherCurrencies?.usd;
  }
  const handleEnrollmentClick = async userSuggestedPrice => {
    const cost = courseCost
      .replace("$", "")
      .replace("€", "")
      .replace("£", "");
    setLoading(true);
    const courseDataObject = {
        course: {
          courseType,
          courseId,
          title,
          provider,
          providerUrl,
          theme,
          courseAvailability,
          courseDuration,
          slug,
          courseCost: courseCostBasedOnLocation,
          currencySymbol,
          currency,
          flexiblePayment,
        },
      },
      checkIfIsZero = amount => Number(amount) === 0;

    if (userSuggestedPrice && !checkIfIsZero(userSuggestedPrice)) {
      courseDataObject.course = {
        ...courseDataObject.course,
        flexible_amount: userSuggestedPrice,
      };
    }

    if (isLoggedIn()) {
      if (
        (!isNaN(cost) && !checkIfIsZero(userSuggestedPrice)) ||
        (userSuggestedPrice && !checkIfIsZero(userSuggestedPrice))
      ) {
        return navigate("/payment/", {
          state: courseDataObject,
        });
      }

      const userDetails = getUserDetails(),
        {
          external_id,
          email,
          first_name,
          last_name,
          provider_id,
        } = userDetails;

      const argsObject = {
        external_id,
        email,
        first_name,
        provider_url: providerUrl,
        last_name,
        course_id: courseId,
        provider,
        course_name: title,
      };

      if (provider_id !== "") argsObject.provider_id = provider_id;

      const courseEnrollment = await _post({
        endpoint: "enrollments",
        args: argsObject,
      });

      if (
        courseEnrollment.success &&
        provider_id === "" &&
        provider.toUpperCase() === "THINKIFIC"
      ) {
        saveUserDetails({
          external_id,
          email,
          first_name,
          last_name,
          provider_id: courseEnrollment.data.user.provider_id,
        });
        // A future release of Chrome will only deliver cookies with cross-site requests if they are set with `SameSite=None` and `Secure`. - 1/15/20
      }

      setLoading(false);

      if (courseEnrollment.success) {
        setEnrollmentNotification({ status: "success", type: "course" });
        window.scrollTo({
          top: 0,
          left: 0,
        });
      } else {
        setEnrollmentNotification({ status: "fail", type: "course" });
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    } else {
      const pendingCourseEnrollmentDetailsObject = {
        courseType,
        courseId,
        provider,
        providerUrl,
        theme,
        title,
        courseCost: courseCostBasedOnLocation,
        currencySymbol,
        currency,
        courseAvailability,
        courseDuration,
        slug,
        flexiblePayment,
      };
      if (flexiblePayment) {
        pendingCourseEnrollmentDetailsObject.flexible_amount = userSuggestedPrice;
      }

      localStorage.setItem(
        "pendingCourseEnrollmentDetails",
        JSON.stringify(pendingCourseEnrollmentDetailsObject)
      );

      const login_url = process.env.GATSBY_login_url;
      return window.location.replace(`${login_url}sso`);
      //redirect to login page
    }
  };

  return enrollmentNotification ? (
    <EnrollmentNotification
      setEnrollmentNotification={setEnrollmentNotification}
      notification={enrollmentNotification}
      notificationData={enrollmentNotificationData}
    />
  ) : (
    <>
      {courseImage && (
        <CourseDetailHero
          courseImage={courseImage}
          hubspotClassName={hubspotClassName}
          courseType={courseType}
          title={title}
          provider={provider}
          providerUrl={providerUrl}
          shortDesc={shortDesc}
          courseTheme={theme}
          themeNumber={themeNumber}
          viewCourseCatalogLink={viewCourseCatalogLink}
          viewCourseCatalogText={viewCourseCatalogText}
          courseCost={courseCostBasedOnLocation}
          courseAvailability={courseAvailability}
          courseDuration={courseDuration}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          onEnrollmentClick={handleEnrollmentClick}
          enrollmentStatus={enrollmentStatus}
          courseStartDate={courseStartDate}
          headerCourseStartDate={headerCourseStartDate}
          onGoToCourseClick={handleGoToCourseClick}
          sso={sso}
          redirectUrl={redirectUrl}
          validatingToken={validatingToken}
          flexiblePayment={flexiblePayment}
          setShowFlexiblePaymentModal={setShowFlexiblePaymentModal}
          newTheme={newTheme}
        />
      )}

      <StickyHeader
        hubspotClassName={hubspotClassName}
        courseType={courseType}
        title={title}
        provider={provider}
        providerUrl={providerUrl}
        courseTheme={theme}
        themeNumber={themeNumber}
        courseCost={courseCostBasedOnLocation}
        courseAvailability={courseAvailability}
        courseDuration={courseDuration}
        ctaText={ctaText}
        ctaUrl={ctaUrl}
        onEnrollmentClick={handleEnrollmentClick}
        enrollmentStatus={enrollmentStatus}
        courseStartDate={courseStartDate}
        onGoToCourseClick={handleGoToCourseClick}
        isAcceleratorDetail={false}
        fullDeviceWidth
      />
      {courseBookPromo && <CourseBookPromo promoDetails={courseBookPromo} />}
      {financialAid && (
        <FinancialAid
          description={financialAid.description.description}
          link={financialAid.link}
        />
      )}
      <ContentWrapper>
        {whatYoullLearn && (
          <LearnSection
            heading={whatYoullLearnHeading}
            learningBullets={whatYoullLearn}
            videoPreviewUrl={videoPreviewUrl}
            title={title}
            videoThumbnail={videoThumbnail}
            caption={watchVideoCaption}
          />
        )}
        {courseSyllabus && (
          <CourseSyllabus
            courseSyllabusHeader={courseSyllabusHeader}
            courseSyllabus={courseSyllabus}
          />
        )}
        <CourseBenefits courseBenefits={courseBenefits} />
        {testimonials && (
          <TestimonialSlider
            hasGrayBackground={true}
            testimonials={testimonials}
          />
        )}

        {courseDescription && (
          <CourseDescription
            courseDescriptionHeading={courseDescriptionHeading}
            courseDescriptionText={courseDescription}
            readMoreLessTitle={readMoreLessTitle}
          />
        )}
        {aboutTheInstructors && aboutTheInstructorsTitle && (
          <FeaturedInThisCourse
            aboutTheInstructorsTitle={aboutTheInstructorsTitle}
            instructorsInfo={aboutTheInstructors}
          />
        )}
        {supporters && supportersDescriptionHeading && (
          <Supporters
            supportersDescriptionHeading={supportersDescriptionHeading}
            supportersArray={supporters}
          />
        )}
        {moreCourses && moreCoursesHeading && languageCode !== "es" && (
          <CourseThemeCatalog
            heading={moreCoursesHeading}
            moreCourses={moreCourses}
          />
        )}
      </ContentWrapper>
      <FlexiblePaymentModal
        showFlexiblePaymentModal={showFlexiblePaymentModal}
        setShowFlexiblePaymentModal={setShowFlexiblePaymentModal}
        courseCost={courseCostBasedOnLocation}
        handleEnrollmentClick={handleEnrollmentClick}
        currencySymbol={currencySymbol}
        suggestedPrice={suggestedPrice}
      />
    </>
  );
};

CourseDetail.propTypes = {
  courseImage: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSet: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  hubspotClassName: PropTypes.string,
  courseType: PropTypes.string,
  courseId: PropTypes.string,
  title: PropTypes.string,
  provider: PropTypes.string,
  providerId: PropTypes.string,
  shortDesc: PropTypes.string,
  courseTheme: PropTypes.string,
  courseCost: PropTypes.string,
  courseAvailability: PropTypes.string,
  courseDuration: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  courseBookPromo: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      link: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      secondLink: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      image: PropTypes.shape({
        fluid: PropTypes.shape({
          srcSet: PropTypes.string,
        }),
      }),
    })
  ),
  financialAid: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      link: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
  videoPreviewUrl: PropTypes.string,
  whatYoullLearn: PropTypes.array,
  courseBenefits: PropTypes.shape({
    benefit1: PropTypes.string,
    description1: PropTypes.string,
    benefit2: PropTypes.string,
    description2: PropTypes.string,
    benefit3: PropTypes.string,
    description3: PropTypes.string,
    benefit4: PropTypes.string,
    description4: PropTypes.string,
  }),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      authorname: PropTypes.string,
      authorTitle: PropTypes.string,
      testimonialQuote: PropTypes.shape({
        testimonialQuote: PropTypes.string,
      }),
    })
  ),
  courseSyllabus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
  CourseDescription: PropTypes.string,
  courseDescriptionHeading: PropTypes.string,
  readMoreLessTitle: PropTypes.arrayOf(PropTypes.string),
  aboutTheInstructors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      bioAbout: PropTypes.shape({
        bioAbout: PropTypes.string,
      }),
    })
  ),
  supportersDescriptionHeading: PropTypes.string,
  supporters: PropTypes.arrayOf(
    PropTypes.shape({
      photoLogo: PropTypes.shape({
        fluid: PropTypes.shape({
          srcSet: PropTypes.string,
          description: PropTypes.string,
        }),
      }),
    })
  ),
  aboutThisCourseType: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      linkText: PropTypes.string,
    }),
    benefit1: PropTypes.string,
    benefits2: PropTypes.string,
    benefits3: PropTypes.string,
    benefits4: PropTypes.string,
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string,
        aspectRatio: PropTypes.number,
      }),
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  courseThemeMatchArray: PropTypes.array,
  enrollmentStatus: PropTypes.string,
  courseStartDate: PropTypes.string,
  enrollmentNotificationData: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.shape({
      url: PropTypes.string,
      linkText: PropTypes.string,
    }),
  }),
  setEnrollmentNotification: PropTypes.func,
  isLoggedIn: PropTypes.func,
  setLoading: PropTypes.func,
};

export const query = graphql`
  fragment CourseDetail on ContentfulCourseDetailPage {
    languageCode
    flexiblePayment
    newTheme {
      title
      info
    }
    courseImage {
      fluid {
        srcSet
        srcSetWebp
        aspectRatio
      }
      file {
        url
      }
      description
    }
    seoImage {
      fluid {
        srcSet
      }
      file {
        url
      }
    }
    viewCourseCatalog {
      linkText
      url
    }
    darkImageOverlayText
    courseId
    slug
    courseType
    provider
    providerUrl
    title
    metaDescription
    shortDescription {
      shortDescription
    }
    theme: theme20 {
      theme
      themeNumber
    }
    cta {
      url
      linkText
    }
    courseCost
    courseCostInOtherCurrencies {
      pound
      euro
      usd
    }
    courseAvailability
    courseDuration
    courseStartDate
    courseBookPromo {
      description {
        description
      }
      factsHighlight
      link {
        url
        linkText
      }
      secondLink {
        url
        linkText
      }
      thirdLink {
        url
        linkText
      }
      image {
        fluid {
          aspectRatio
          srcSetWebp
          srcSet
        }
      }
    }
    financialAid {
      description {
        description
      }
      link {
        linkText
        url
      }
    }
    videoPreviewUrl
    videoThumbnail {
      title
      fluid {
        srcSet
      }
    }
    watchVideoCaption
    whatYoullLearnHeading
    whatYoullLearn
    courseBenefits {
      benefit1
      description1
      icon1Image {
        fluid {
          srcSet
          srcWebp
          aspectRatio
        }
      }
      benefit2
      description2
      icon2Image {
        fluid {
          srcSet
          srcWebp
          aspectRatio
        }
      }
      benefit3
      description3
      icon2Image {
        fluid {
          srcSet
          srcWebp
          aspectRatio
        }
      }
      benefit4
      description4
      icon4Image {
        fluid {
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }
    testimonials {
      authorName
      authorTitle
      testimonialQuote {
        testimonialQuote
      }
    }
    courseSyllabusHeader
    courseSyllabus {
      title
      description {
        description
      }
    }
    courseDescriptionHeading
    courseDescription {
      courseDescription
    }
    readMore
    heading1
    aboutTheInstructors {
      name
      bioAbout {
        bioAbout
      }
      sectionTitle
      photoLogo {
        fluid {
          srcSet
        }
      }
    }
    heading2
    supporters {
      photoLogo {
        fluid {
          srcSet
          aspectRatio
        }
        description
      }
      bioAbout {
        bioAbout
      }
      cta {
        url
        linkText
      }
    }
    aboutThisCourseType {
      heading
      description
      link {
        linkText
        url
      }
      benefit1
      benefit2
      benefit3
      benefit4
      image {
        fluid {
          srcSet
          aspectRatio
        }
        title
        description
      }
    }
    moreCoursesHeading
    moreCourses {
      languageCode
      flexiblePayment
      suggestedPrice
      courseImage {
        fluid {
          srcSet
          srcSetWebp
          aspectRatio
        }
        file {
          url
        }
        description
      }
      seoImage {
        fluid {
          srcSet
        }
        file {
          url
        }
      }
      viewCourseCatalog {
        linkText
        url
      }
      darkImageOverlayText
      courseId
      slug
      courseType
      provider
      providerUrl
      title
      metaDescription
      shortDescription {
        shortDescription
      }
      theme: theme20 {
        theme
        themeNumber
      }
      cta {
        url
        linkText
      }
      courseCost
      courseCostInOtherCurrencies {
        pound
        euro
        usd
      }
      courseAvailability
      courseDuration
      courseStartDate
      courseBookPromo {
        description {
          description
        }
        factsHighlight
        link {
          url
          linkText
        }
        secondLink {
          url
          linkText
        }
        thirdLink {
          url
          linkText
        }
        image {
          fluid {
            aspectRatio
            srcSetWebp
            srcSet
          }
        }
      }
      financialAid {
        description {
          description
        }
        link {
          linkText
          url
        }
      }
      videoPreviewUrl
      videoThumbnail {
        title
        fluid {
          srcSet
        }
      }
      watchVideoCaption
      whatYoullLearnHeading
      whatYoullLearn
      courseBenefits {
        benefit1
        description1
        icon1Image {
          fluid {
            srcSet
            srcWebp
            aspectRatio
          }
        }
        benefit2
        description2
        icon2Image {
          fluid {
            srcSet
            srcWebp
            aspectRatio
          }
        }
        benefit3
        description3
        icon2Image {
          fluid {
            srcSet
            srcWebp
            aspectRatio
          }
        }
        benefit4
        description4
        icon4Image {
          fluid {
            srcSet
            srcWebp
            aspectRatio
          }
        }
      }
      testimonials {
        authorName
        authorTitle
        testimonialQuote {
          testimonialQuote
        }
      }
      courseSyllabusHeader
      courseSyllabus {
        title
        description {
          description
        }
      }
      courseDescriptionHeading
      courseDescription {
        courseDescription
      }
      readMore
      heading1
      aboutTheInstructors {
        name
        bioAbout {
          bioAbout
        }
        sectionTitle
        photoLogo {
          fluid {
            srcSet
          }
        }
      }
      heading2
      supporters {
        photoLogo {
          fluid {
            srcSet
            aspectRatio
          }
          description
        }
        bioAbout {
          bioAbout
        }
        cta {
          url
          linkText
        }
      }
      aboutThisCourseType {
        heading
        description
        link {
          linkText
          url
        }
        benefit1
        benefit2
        benefit3
        benefit4
        image {
          fluid {
            srcSet
            aspectRatio
          }
          title
          description
        }
      }
    }
  }
`;

export default CourseDetail;
