import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { theme } from "../../../utils/global-styles/theme";
import Markdown from "markdown-to-jsx";

import BlurredContentContainer from "../../shared/BlurredContentContainer/BlurredContentContainer";

const CourseDescription = ({
  courseDescriptionText,
  readMoreLessTitle,
  courseDescriptionHeading,
}) => {
  const CourseDescGrid = styled.div`
      display: grid;
      grid-template-rows: 1fr 88px;
      grid-template-columns: 324px 1fr 324px;
      font-family: ${theme.fontFaces.headers};
      padding-top: 66px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 168px 1fr 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: 1fr 18px;
        grid-template-columns: 40px 1fr 40px;
      }
    `,
    CourseDescContentContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      height: max-content;
    `,
    Title = styled.h2`
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 42px;
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `;

  return (
    <CourseDescGrid>
      <CourseDescContentContainer>
        <Title>{courseDescriptionHeading || "Course Description"}</Title>
        <BlurredContentContainer
          readMoreLessTitle={readMoreLessTitle}
          blurHeight="80px"
          content={
            <Markdown
              options={{
                overrides: {
                  p: {
                    props: {
                      className: "markdown-paragraph",
                    },
                  },
                  a: {
                    props: {
                      className: "markdown-link",
                    },
                  },
                },
              }}
              className="markdown-paragraph"
            >
              {courseDescriptionText}
            </Markdown>
          }
        />
      </CourseDescContentContainer>
    </CourseDescGrid>
  );
};

CourseDescription.propTypes = {
  courseDescriptionText: PropTypes.string,
};

export default CourseDescription;
