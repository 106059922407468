import { graphql, useStaticQuery } from "gatsby";

const useFlexiblePaymentModalData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFlexiblePaymentModal(
        filter: {
          queryIdentifier: { eq: "flexiblePaymentModal" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          description {
            description
          }
          suggestedPrice
          inputFieldLabel
          inputFieldCurrency
          paymentButton
        }
        }
    }
  `);
  return data.allContentfulFlexiblePaymentModal.nodes[0];
};

export default useFlexiblePaymentModalData;
